import { css } from '@emotion/react';
import { MutableRefObject, ReactNode, useContext, useRef } from 'react';
import { Color, colors } from '../../utils/styleguide';
import BuildingBlockInfo from '../component-library/layout/BuildingBlockInfo';
import HighlightSectionLayout from '../component-library/layout/HighlightSection/HighlightSectionLayout';
import SectionBackground from '../component-library/SectionBackground';
import { TemplatingContext } from './contexts/TemplatingContext';
import Image, { ImageStyleProps } from './Image';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';
import sectionToCompatibleServerSideProps from './util/sectionToCompatibleServerSideProps';
import { getVideoById, Video } from '../../utils/api/videoApi';
import VideoPlayer from '../component-library/video/InlineVideoPlayer';
import styled from '@emotion/styled';
import PropsComponent from '../component-library/types/PropsComponent';
import { isTypeIllustratedText, isTypeImageLink } from '../../../@types/generated';

type ServerSideProps = {
  video?: Video;
};

const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const HighlightSection: PageSectionComponent<ServerSideProps> = ({
  section,
  serverSideProps: { video },
}) => {
  const { references } = section.fields;
  const {
    id,
    backgroundColor,
    backgroundImage,
    backgroundImageLandscape,
    backgroundImagePortrait,
    reversed,
    reversedMobile,
    overline,
    title,
    description,
    button,
    media,
    settings,
  } = sectionToCompatibleProps({ section });
  const headlineColor = colors[settings?.headlineColor as Color];
  const descriptionColor = colors[settings?.descriptionColor as Color];
  const { templateReplacer } = useContext(TemplatingContext);
  const href = button?.href && templateReplacer(button.href);

  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const { image } = media;
  let mediaComponent: ReactNode | undefined;

  if (image) {
    mediaComponent = (
      <Image
        asset={image}
        wrapperCss={css`
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
        `}
        css={css`
          height: 100%;
          width: 100%;
          object-fit: ${'imageFit' in settings && typeof settings.imageFit === 'string'
            ? settings?.imageFit
            : 'contain'};
        `}
      />
    );
  }

  if (video) {
    mediaComponent = (
      <PlayerWrapper>
        <VideoPlayer video={video} videoRef={videoRef} autoPlay loop />
      </PlayerWrapper>
    );
  }

  let logo: PropsComponent<ImageStyleProps> | undefined;

  if (references && references.length === 1) {
    const reference = references[0];
    if (reference && (isTypeImageLink(reference) || isTypeIllustratedText(reference))) {
      logo = (props: ImageStyleProps) => <Image asset={reference.fields.image} {...props} />;
    }
  }

  return (
    <SectionBackground
      id={id}
      backgroundColor={backgroundColor}
      backgroundImage={{
        landscape: backgroundImageLandscape?.fields.file?.url ?? backgroundImage?.fields.file?.url,
        portrait: backgroundImagePortrait?.fields.file?.url,
      }}
    >
      <HighlightSectionLayout
        reversed={reversed}
        reversedMobile={reversedMobile}
        fixedMediaHeight={media.fixedHeight}
        dynamicMediaHeight={media.dynamicHeight}
        content={
          <BuildingBlockInfo
            overline={overline}
            title={<RichText document={title} textColor={headlineColor} />}
            description={
              <RichText
                document={description}
                textColor={descriptionColor}
                withCheckmark={!!settings?.withCheckmark}
              />
            }
            buttonLabel={button?.label}
            buttonPrompt={button?.prompt}
            buttonColor={button?.colorContext}
            largeButton={button?.size === 'large'}
            Logo={logo}
            href={href}
          />
        }
        media={mediaComponent}
      />
    </SectionBackground>
  );
};

HighlightSection.getCustomServerSideProps = async ({ section }) => {
  const { videoIdLandscape, videoIdPortrait } = sectionToCompatibleServerSideProps({ section });
  const videoId = videoIdLandscape || videoIdPortrait;
  const video = await getVideoById(videoId);

  return { video };
};

export default HighlightSection;
