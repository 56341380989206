import styled from '@emotion/styled';
import { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { sendViewVideoCompletedEvent, sendViewVideoEvent } from '../../utils/analytics/video';
import { useOutsideClick } from '../../utils/hooks/useOutsideClick';
import useStreamingVideo from '../../utils/hooks/useStreamingVideo';
import { spacing } from '../../utils/styleguide';
import OverlayMedia from './media/OverlayMedia';

const FullVideo = styled.video`
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const PostVideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing[4]}px;
  box-sizing: border-box;
`;

export type Props = {
  src?: string;
  onClose: ({ videoRef }: { videoRef: RefObject<HTMLVideoElement> }) => void;
  aspectRatio?: number;
  startTime?: number;
  videoRef: RefObject<HTMLVideoElement>;
  videoId: number;
  header?: ReactNode;
  postVideo?: ReactNode;
};

export default function OverlayVideoPlayer({
  src,
  onClose,
  aspectRatio = 9 / 16,
  startTime = undefined,
  videoRef,
  videoId,
  header,
  postVideo,
}: Props) {
  const { doneLoading: doneLoadingScript, play } = useStreamingVideo({ src, ref: videoRef });
  const boxRef = useRef(null);
  const closeVideoHandler = useCallback(() => {
    sendViewVideoCompletedEvent({ videoId, videoRef });
    onClose({ videoRef });
  }, [onClose, videoId, videoRef]);
  const [ended, setEnded] = useState(false);

  useOutsideClick({ innerRef: boxRef, onOutsideClick: () => closeVideoHandler() });

  useEffect(() => {
    if (doneLoadingScript) {
      sendViewVideoEvent({ videoId });
      play();
    }
  }, [doneLoadingScript, play, videoId]);

  useEffect(() => {
    const currentRef = videoRef.current;
    const onEvent = () => closeVideoHandler();
    currentRef?.addEventListener('webkitendfullscreen', onEvent);
    return () => currentRef?.removeEventListener('webkitendfullscreen', onEvent);
  });

  useEffect(() => {
    if (startTime && videoRef?.current?.currentTime !== undefined) {
      videoRef.current.currentTime = startTime;
    }
  }, [videoRef, startTime]);

  const showPostVideo = ended && !!postVideo;

  return (
    <OverlayMedia onClose={closeVideoHandler} aspectRatio={aspectRatio} header={header}>
      {!showPostVideo && (
        <FullVideo
          crossOrigin="anonymous"
          ref={videoRef}
          src={src}
          controls
          onEnded={() => setEnded(true)}
          playsInline // prevents the video from going fullscreen on ios devices. see https://webkit.org/blog/6784/new-video-policies-for-ios/
        />
      )}
      {showPostVideo && <PostVideoOverlay>{postVideo}</PostVideoOverlay>}
    </OverlayMedia>
  );
}
