import { TypePageSection } from '../../../../@types/generated';
import getSettingsFromSection from '../../../utils/getSettingsFromSection';
import orientedMediaToCompatibleProps from './orientedMediaToCompatibleProps';

export default function sectionToCompatibleServerSideProps({
  section,
}: {
  section: TypePageSection;
}) {
  const settings = getSettingsFromSection(section);

  const {
    videoIdLandscape: backgroundVideoIdLandscape,
    videoIdPortrait: backgroundVideoIdPortrait,
  } = orientedMediaToCompatibleProps({
    orientedMedia: section.fields.backgroundEntry?.fields.orientedMedia,
    settings: {
      video: settings?.backgroundVideo,
    },
  });

  const { videoIdLandscape, videoIdPortrait } = orientedMediaToCompatibleProps({
    orientedMedia: section.fields.orientedMedia,
    settings,
  });

  return {
    backgroundVideoIdLandscape,
    backgroundVideoIdPortrait,
    videoIdLandscape,
    videoIdPortrait,
  };
}
