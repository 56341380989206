import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useOutsideClick } from '../../../utils/hooks/useOutsideClick';
import { breakpoints, colors } from '../../../utils/styleguide';
import IconButton from '../buttons/IconButton';
import AspectRatioBox from '../layout/AspectRatioBox';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.div<{ width: number | undefined }>`
  display: flex;
  margin: 0 auto;
  align-items: center;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  max-width: 90%;
  ${breakpoints.tablet} {
    max-width: 80%;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const CloseButton = styled(IconButton)`
  background-color: transparent;
  width: 32px;
  height: 32px;
  padding: 0;
`;

const AspectRatioWrapper = styled.div<{ width: number | undefined }>`
  align-self: center;
  display: flex;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  max-width: 90%;
  ${breakpoints.tablet} {
    max-width: 80%;
  }
`;
type Props = {
  aspectRatio?: number;
  onClose: () => void;
  children: ReactNode | ReactNode[];
  header?: ReactNode;
};

export default function OverlayMedia({ onClose, aspectRatio = 9 / 16, children, header }: Props) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [widthWithRatio, setWidthWithRatio] = useState<number>();

  useOutsideClick({ innerRef: boxRef, onOutsideClick: () => onClose() });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Header width to stay in sync with media width
  useLayoutEffect(() => {
    function onChange() {
      if (overlayRef.current && headerRef.current) {
        const availableHeight = overlayRef.current.clientHeight - headerRef.current.clientHeight;
        const widthWithRatio = availableHeight ? aspectRatio * availableHeight : undefined;
        setWidthWithRatio(widthWithRatio);
      }
    }
    window.addEventListener('resize', onChange);
    onChange();
    return () => window.removeEventListener('resize', onChange);
  }, [aspectRatio]);

  return createPortal(
    <Overlay ref={overlayRef}>
      <Header width={widthWithRatio} ref={headerRef}>
        {header}
        <Spacer />
        <CloseButton
          ariaLabel="x-cross"
          iconName="x-cross"
          stroke={colors.white}
          fill={colors.white}
          iconSize={12}
          onClick={onClose}
        />
      </Header>
      <AspectRatioWrapper width={widthWithRatio}>
        <AspectRatioBox aspectRatio={aspectRatio} boxRef={boxRef}>
          {children}
        </AspectRatioBox>
      </AspectRatioWrapper>
    </Overlay>,
    document.body,
  );
}
